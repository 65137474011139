<template>
  <div class="appTop_box" id="header_box1">
    <div class="pc_box">
      <div class="apptop_pc_main">
        <div class="header_top_left">
          <div class="logo_box">
            <img
              src="../assets/img/logo.png"
              width="100px"
              alt=""
              @click="$router.replace('/')"
            />
          </div>

          <div class="classifyNameList_box">
            <div
              class="classify_item font18 bounceInDown wow"
              style="animation-duration: 1"
              @click="$router.replace('/')"
            >
              Home
            </div>
            <div
              class="classify_item font18 bounceInDown wow"
              :style="{ 'animation-duration': index + 1.5 }"
              v-for="(item, index) in classifyList"
              :key="index"
              @click="push(item)"
              @mouseover="handleMouseOver(item)"
              @mouseleave="handleMouseLeave(item)"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
        <div class="search_box" @click.stop="$router.push('/search')">
          <input
            v-model="searchInfo"
            type="text"
            
             @click.stop="$router.push('/search')"
            class="input_main"
            placeholder="Please enter"
          />
          <img class="pro_08" src="../assets/images/pro_08.png" alt="" />
        </div>
        <!-- 二级分类 -->
        <div
          class="second_classify_box"
          v-if="showSecondClassify"
          @mouseleave="secondMouseLeave()"
        >
          <div class="cla_main_box">
            <div
              class="classify_item font18"
              :class="secondIndex == index && 'text-red'"
              @mouseover="secondMouseOver(item, index)"
              v-for="(item, index) in secondClassifyList"
              @click="push(item)"
              :key="index"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mob_box">
      <div class="mob_top_box">
        <img class="logo_pic" src="../assets/img/logo.png" alt="" />

        <!-- 其他功能 -->
        <div class="other_function_box">
          <img
            class="search_pic"
            @click="$router.push('/search')"
            src="../assets/img/search.png"
            alt=""
          />
          <img
            class="menu_pic"
            @click="showPopup = true"
            src="../assets/img/menu.png"
            alt=""
          />
        </div>
      </div>
      <div class="search_container" v-if="showSearch">
        <div class="search_box">
          <input
            v-model="searchInfo"
            type="text"
            class="input_main"
            disabled
            :placeholder="$t('Please enter')"
          />
          <img class="pro_08" src="../assets/images/pro_08.png" alt="" />
        </div>
        <div class="btn_cancle" @click="showSearch = false">Cancel</div>
      </div>
    </div>
    <van-popup
      v-model="showPopup"
      position="left"
      :style="{ height: '100%', width: '100%' }"
      closeable
    >
      <div class="pop_main_box">
        <img class="logo_pic" src="../assets/img/mobile-logo.png" alt="" />
        <van-collapse v-model="activeNames" accordion>
          <van-cell
            title="Home"
            @click="
              $router.replace('/');
              showPopup = false;
            "
            is-link
          />

          <van-cell
            :key="index"
            :title="item.title"
            @click="
              push(item);
              showPopup = false;
            "
            v-for="(item, index) in secondClassifyList"
            is-link
          />
          <!-- <van-collapse-item :key="index" :title="item.title" v-for="(item,index) in secondClassifyList" :name="index"></van-collapse-item> -->
        </van-collapse>
      </div>
    </van-popup>
  </div>
</template>
<style>
</style>

<script>
import { WOW } from "wowjs";
export default {
  name: "",
  data() {
    return {
      productList: [{}, {}, {}, {}, {}, {}],
      secondIndex: 999,
      show: true,
      showPopup1: true,
      showSelectLang: false,
      routed_cate_id: this.$route.cate_id,
      classifyList: this.$categories_header,
      allClassList: [],
      secondClassifyList: this.$categories,
      showSecondClassify: false,
      searchInfo: "",
      showSearch: false,
      showPopup: false,
      activeNames: ["1"],
    };
  },
  methods: {
    push(item) {

      if (this.$route.path == "/product") {
        this.$bus.$emit("change", item.children[0].id);
      }
      this.$router.push({
        path: "/product",
        query: {
          cate_id: item.children[0].id,
        },
      });
    },
    handleMouseOver(item) {
      if (item.id == 891111) {
        // this.secondClassifyList = ;
        this.showSecondClassify = true;
      }
    },
    handleMouseLeave(item) {
      //    this.showSecondClassify=false;
    },
    secondMouseOver(item, index) {
      this.secondIndex = index;
    },
    secondMouseLeave() {
      this.secondIndex = 99999;
      this.showSecondClassify = false;
    },
    getCategoriesData() {
      this.$api.getCategories().then((res) => {
        if (res.data.code == 200) {
          this.secondClassifyList = res.data.data;
        }
      });
    },
  },
  mounted() {
    //dom 准备完毕
    // 监听页面的滚动事件
    window.onscroll = function () {
      const header = document.getElementById("header_box1");

      // 如果页面向下滚动超过 50px，设置头部背景为透明
      if (window.scrollY > 50) {
        header.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
      } else {
        // 如果回到顶部，将背景改回黑色
        header.style.backgroundColor = "black";
      }
    };
    var wow = new WOW({
      boxClass: "wow", // animated element css class (default is wow)
      animateClass: "animated", // animation css class (default is animated)
      offset: 0, // distance to the element when triggering the animation (default is 0)
      mobile: true, // trigger animations on mobile devices (default is true)
      live: true, // act on asynchronously loaded content (default is true)
      callback: function (box) {
        // the callback is fired every time an animation is started
      },
      scrollContainer: null, // optional scroll container selector, otherwise use window
    });
    wow.init();
  },
  created() {
    //应用创建t
    this.getCategoriesData();
  },
};
</script>

<style>
.van-image__img {
  border-radius: 8px !important;
}
input::placeholder {
  color: #fff;
}
</style>
<style lang="less">
.appTop_box {
  background-color: rgba(0, 0, 0, 1);
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;

  .second_classify_box {
    position: fixed;
    top: 80px;
    width: 100%;
    left: 0;
    background-color: rgba(0, 0, 0, 1);
    // border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 24px 0;

    .cla_main_box {
      width: 1400px;
      //   height: 80px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      gap: 50px;
      row-gap: 28px;

      .classify_item {
        color: #fff;
        font-weight: 700;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .pc_box {
    height: 100%;
    // color: #fff;
    .apptop_pc_main {
      width: 1400px;
      margin: 0 auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .header_top_left {
        height: 100%;
        display: flex;
        align-items: center;

        .logo_box {
          height: 100%;
          display: flex;
          align-items: center;
        }

        .classifyNameList_box {
          display: flex;
          justify-content: center;
          gap: 25px;
          margin-left: 25px;
          flex-wrap: wrap;

          .classify_item {
            color: #fff;
            font-weight: 700;
            cursor: pointer;
          }

          .classify_item:hover {
            color: #ff186b;
            /* 鼠标悬停时的颜色，可以根据需要更改 */
          }
        }
      }

      .search_box {
        width: 340px;
        margin: 0 40px;
        height: 50px;
        border-radius: 80px;
        border: 1px solid #e2e8f0;
        display: flex;
        align-items: center;
        padding: 0 16px;

        .input_main {
          width: 100%;
          height: 100%;
          background: none;
          border: none;
          color: #222;
          font-size: 16px;
          max-height: 44px;
        }

        .pro_08 {
          width: 25px;
        }
      }
    }
  }

  .mob_box {
    display: none;
  }
}

.pop_main_box {
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 100%;
  padding: 0 16px;

  .logo_pic {
    height: 50px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 960px) {
  .pc_box {
    display: none !important;
    width: 100% !important;
  }

  .appTop_box {
    height: 60px !important;
  }

  .mob_box {
    display: block !important;
    width: 100%;

    .search_container {
      position: absolute;
      width: 100%;
      height: 60px;
      top: 0;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;

      .search_box {
        height: 40px;

        flex: 1;
        border-radius: 80px;
        border: 1px solid #e2e8f0;
        display: flex;
        align-items: center;
        padding: 0 16px;
        margin-right: 15px;

        .input_main {
          width: 100%;
          height: 100%;
          background: none;
          border: none;
          color: #222;
          font-size: 16px;
          max-height: 44px;
        }

        .pro_08 {
          width: 25px;
        }
      }
    }

    .mob_top_box {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      position: relative;

      .logo_pic {
        height: 35px;
      }

      .other_function_box {
        display: flex;
        align-items: center;

        .search_pic {
          height: 25px;
          margin-right: 15px;
        }

        .menu_pic {
          height: 25px;
        }
      }
    }
  }
}
</style>