import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import i18n from "./lang/index";
import { Cell, Popup, Loading, Icon, Empty, Image as VanImage, ImagePreview } from 'vant';
import { Collapse, NoticeBar, Step, Steps, CollapseItem, Picker, Grid, GridItem, Search, Popover, Checkbox, CheckboxGroup, NavBar, Tabbar, TabbarItem, Swipe, SwipeItem, Switch, DatetimePicker } from 'vant';
import "@/assets/text/text.css";

import '@/assets/css/pages.less'

// import iView from 'view-design';
import 'vant/lib/index.css';

import 'element-ui/lib/theme-chalk/index.css';

import ElementUI from 'element-ui';

// import api from "./api/request_list";
import api from "./api/request_list";
Vue.prototype.$api = api;
import env from "../env";

Vue.prototype.$env = env;

Vue.use(ElementUI);
Vue.use(Cell);
Vue.use(VanImage);
Vue.use(ImagePreview);
Vue.use(Popover);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Loading);
Vue.use(Empty);
Vue.use(Icon);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Search);
Vue.use(NavBar);
Vue.use(TabbarItem);
Vue.use(Tabbar);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Switch);
Vue.use(DatetimePicker);
Vue.use(NoticeBar);






Vue.config.productionTip = false
Vue.prototype._i18n = i18n;

var vues = new Vue({
  router,
  i18n,
  render: h => h(App),
});
const mockData = [
  {
    "id": 27,
    "title": "Air Jordan",
    "children": [
      {
        "id": 28,
        "title": "Air Jordan 1 Low",
        "children": [],
        "desc": "this is jordan 1 low"
      },
      {
        "id": 29,
        "title": "Air Jordan 1 High",
        "children": [],
        "desc": null
      },
      {
        "id": 30,
        "title": "Air Jordan 3",
        "children": [],
        "desc": null
      },
      {
        "id": 31,
        "title": "Air Jordan 4",
        "children": [],
        "desc": null
      },
      {
        "id": 32,
        "title": "Air Jordan 5",
        "children": [],
        "desc": null
      },
      {
        "id": 33,
        "title": "Air Jordan 6",
        "children": [],
        "desc": null
      },
      {
        "id": 34,
        "title": "Air Jordan 11",
        "children": [],
        "desc": null
      },
      {
        "id": 45,
        "title": "Air Jordan 312 Low",
        "children": [],
        "desc": null
      }
    ],
    "desc": null
  },
  {
    "id": 35,
    "title": "Nike",
    "children": [
      {
        "id": 36,
        "title": "Nike Dunk Low",
        "children": [],
        "desc": null
      },
      {
        "id": 37,
        "title": "Nike Shox TL",
        "children": [],
        "desc": null
      },
      {
        "id": 38,
        "title": "AIR MAX SCORPION",
        "children": [],
        "desc": null
      },
      {
        "id": 39,
        "title": "Nike V2K RUN",
        "children": [],
        "desc": null
      },
      {
        "id": 40,
        "title": "Nike V2K TEKON",
        "children": [],
        "desc": null
      },
      {
        "id": 41,
        "title": "Nike Air More Uptempo",
        "children": [],
        "desc": null
      },
      {
        "id": 42,
        "title": "Nike Kobe 6",
        "children": [],
        "desc": null
      },
      {
        "id": 43,
        "title": "Nike Air Max Plus",
        "children": [],
        "desc": null
      },
      {
        "id": 63,
        "title": "Nike Air Force 1",
        "children": [],
        "desc": null
      }
    ],
    "desc": null
  },
  {
    "id": 44,
    "title": "Adidas",
    "children": [
      {
        "id": 46,
        "title": "Yeezy 350",
        "children": [],
        "desc": null
      },
      {
        "id": 47,
        "title": "Yeezy Slides",
        "children": [],
        "desc": null
      },
      {
        "id": 48,
        "title": "Yeezy 700",
        "children": [],
        "desc": null
      },
      {
        "id": 49,
        "title": "Adidas Campus 00S",
        "children": [],
        "desc": null
      },
      {
        "id": 77,
        "title": "Adidas Bad Bunny",
        "children": [],
        "desc": null
      }
    ],
    "desc": null
  },
  {
    "id": 50,
    "title": "New Balance",
    "children": [
      {
        "id": 51,
        "title": "New Balance 530",
        "children": [],
        "desc": null
      },
      {
        "id": 52,
        "title": "New Balance 2002R",
        "children": [],
        "desc": null
      },
      {
        "id": 53,
        "title": "New Balance 1906R",
        "children": [],
        "desc": null
      },
      {
        "id": 54,
        "title": "New Balance 9060",
        "children": [],
        "desc": null
      }
    ],
    "desc": null
  },
  {
    "id": 55,
    "title": "Louis Vuitton",
    "children": [
      {
        "id": 56,
        "title": "Louis Vuitton Trainer",
        "children": [],
        "desc": null
      },
      {
        "id": 57,
        "title": "Louis Vuitton Skate",
        "children": [],
        "desc": null
      }
    ],
    "desc": null
  },
  {
    "id": 58,
    "title": "Balenciaga",
    "children": [
      {
        "id": 59,
        "title": "Balenciaga 1.0",
        "children": [],
        "desc": null
      },
      {
        "id": 69,
        "title": "Balenciaga x Adidas",
        "children": [],
        "desc": null
      }
    ],
    "desc": null
  },
  {
    "id": 60,
    "title": "BAPE STA LOW",
    "children": [
      {
        "id": 85,
        "title": "BAPE STA LOW",
        "children": [],
        "desc": null
      }
    ],
    "desc": null
  },
  {
    "id": 61,
    "title": "Travis Scott",
    "children": [
      {
        "id": 86,
        "title": "Travis-Scott",
        "children": [],
        "desc": null
      }
    ],
    "desc": null
  },
  {
    "id": 62,
    "title": "OFF-WHITE",
    "children": [
      {
        "id": 87,
        "title": "OFF WHITE",
        "children": [],
        "desc": null
      }
    ],
    "desc": null
  },
  {
    "id": 64,
    "title": "Timberlands",
    "children": [
      {
        "id": 90,
        "title": "Timberlands",
        "children": [],
        "desc": null
      }
    ],
    "desc": null
  },
  {
    "id": 65,
    "title": "Rick Owens",
    "children": [
      {
        "id": 70,
        "title": "Converse Rick Owens",
        "children": [],
        "desc": null
      },
      {
        "id": 71,
        "title": "Rick Owens Low",
        "children": [],
        "desc": null
      }
    ],
    "desc": null
  },
  {
    "id": 66,
    "title": "Amiri",
    "children": [
      {
        "id": 67,
        "title": "Amiri Ma-1",
        "children": [],
        "desc": null
      }
    ],
    "desc": null
  },
  {
    "id": 68,
    "title": "Lanvin",
    "children": [
      {
        "id": 91,
        "title": "Lanvin",
        "children": [],
        "desc": null
      }
    ],
    "desc": null
  },
  {
    "id": 72,
    "title": "Redemption",
    "children": [
      {
        "id": 92,
        "title": "Redemption",
        "children": [],
        "desc": null
      }
    ],
    "desc": null
  },
  {
    "id": 73,
    "title": "Dior",
    "children": [
      {
        "id": 74,
        "title": "Dior B30",
        "children": [],
        "desc": null
      },
      {
        "id": 75,
        "title": "Dior B22",
        "children": [],
        "desc": null
      }
    ],
    "desc": null
  },
  {
    "id": 76,
    "title": "MLB",
    "children": [
      {
        "id": 93,
        "title": "MLB",
        "children": [],
        "desc": null
      }
    ],
    "desc": null
  },
  {
    "id": 88,
    "title": "Down Coat",
    "children": [
      {
        "id": 78,
        "title": "Canada Goose",
        "children": [],
        "desc": null
      },
      {
        "id": 79,
        "title": "Moncler",
        "children": [],
        "desc": null
      },
      {
        "id": 80,
        "title": "Celine",
        "children": [],
        "desc": null
      },
      {
        "id": 81,
        "title": "THE NORTH FACE",
        "children": [],
        "desc": null
      }
    ],
    "desc": null
  },
  {
    "id": 89,
    "title": "Clothes",
    "children": [
      {
        "id": 82,
        "title": "Hoodie",
        "children": [],
        "desc": null
      },
      {
        "id": 83,
        "title": "Coat",
        "children": [],
        "desc": null
      },
      {
        "id": 84,
        "title": "Jeans",
        "children": [],
        "desc": null
      }
    ],
    "desc": null
  },
  {
    "id": 94,
    "title": "OFF-WHITE Out Of Office",
    "children": [
      {
        "id": 95,
        "title": "OFF-WHITE Out Of Office",
        "children": [],
        "desc": null
      }
    ],
    "desc": null
  }
];
Vue.prototype.$categories = mockData;
// 保留的ID header
const idsToFind = [27, 44, 50, 61, 62];
var filteredData = mockData.filter(item => idsToFind.includes(item.id));
filteredData.push(
  {
    "id": 891111,
    "title": "ALL",
    "children": [

    ],
    "desc": null
  }
)
Vue.prototype.$categories_header = filteredData;
Vue.prototype.$bus = vues;
vues.$mount('#app');
export default vues

