const config = {
    httpsdns: "https://oopbuystore.com/",
    home_url: "https://docs.google.com/spreadsheets/d/1fRxLp5DlCSkN4d5iwpkIbBjZahefN2-hhGJnNTPIgAw/edit?gid=0#gid=0",
    rate: 6.5, // 汇率
    invite_code:"J1D4QUB3S",// 
    discord_url: "https://discord.gg/YUhQr89Kkt",
    hot_proudts: [
        {
            "id": 2215,
            "title": "AirPods Pro 2",
            "image": "https://oopbuystore.com/storage/images/Airpods 2 pro RMB 70.jpg",
            "price": "70.00",
        },
        
        {
            "id": 2187,
            "title": "Air Jordan 4 Retro CT8527-100",
            "image": "https://oopbuystore.com/storage/images/open1701901822-1234478995-051100000190d42f0c850a210559_1092_1090.jpg",
            "price": "359.00",
        },
        {
            "id": 2167,
            "title": "Air Jordan 4 Retro CU1110-010",
            "image": "https://oopbuystore.com/storage/images/open1701901822-1234478995-4ec200000190d42ef7e30aa0233d_784_782.jpg",
            "price": "359.00",
        },
        {
            "id": 2186,
            "title": "Air Jordan 4 Retro off white DC9533-800",
            "image": "https://oopbuystore.com/storage/images/open1701901822-1234478995-64c800000190d42eeac80a22d234_800_800.jpg",
            "price": "359.00",
        },
        {
            "id": 2193,
            "title": "Balenciaga",
            "image": "https://oopbuystore.com/storage/images/pcitem1753263013-0729000001929072c27a0a239846-unadjust_1120_1120.png",
            "price": "499.00",
        },
        {
            "id": 2015,
            "title": "Dior B30",
            "image": "https://oopbuystore.com/storage/images/微信图片_20241019165859.png",
            "price": "560.00",
        },
        {
            "id": 2155,
            "title": "Jordan 1 Retro High OG SP Travis Scott CD4487-100",
            "image": "https://oopbuystore.com/storage/images/微信图片_20241019165854.png",
            "price": "359.00",
        },
        {
            "id": 2143,
            "title": "Travis Scott Air Jordan 1 DM7866-162",
            "image": "https://oopbuystore.com/storage/images/微信图片_20241019165848.png",
            "price": "359.00",
        },
        {
            "id": 1922,
            "title": "Timberlands boots",
            "image": "https://oopbuystore.com/storage/images/微信图片_20241019165902.png",
            "price": "259.00",
        },
        {
            "id": 1992,
            "title": "Yeezy Slides",
            "image": "https://oopbuystore.com/storage/images/微信图片_20241019170944.png",
            "price": "70.00",
        }
        
        
    ]
}

export default config;