/* eslint-disable no-param-reassign */
import axios from "axios";
import { Toast } from "vant";
import env from "../../env"
import router from "@/router";


const requests = axios.create({
    method: "post",
    baseURL: env.httpsdns,  // imageUrl: "https://"+location.host,,
    timeout: 5 * 1000,
    headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
    },

});
requests.interceptors.request.use(
    (config) => {

     
        // config.headers["lang"] = localStorage.getItem("lang") || 'en';
        // config.headers["Authorization"] = 'Bearer ' +localStorage.getItem("token") || '';
       

        return config;
    },
    (error) => Promise.reject(error)
);
// 301 302
requests.interceptors.response.use(
    (response) => {
        if (response.data.code == 401 || response.data.code == 422) {
            // 弹出授权
        }
        if (response.data.code != 200) {
            // if (response.data.code == 401 || response.data.code == 422) {
            //     router.replace("/login")
               
            // }else{
            //     // Toast(response.data.msg);
            // }
            

        }
        // if()
        // 
        return response;
    },
    (error) => Promise.reject(error)
);

export {
    requests
};