import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ProDuctDetails from "../views/ProDuctDetails.vue";
import ProductList from "../views/ProductList.vue";
import SearchPage from "../views/SearchPage.vue";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
    {
        path: "/", //首页
        name: "Home",
        component: Home,
    },
    {
        path: "/detail", //首页
        name: "ProDuctDetails",
        component: ProDuctDetails,
    },
    {
        path: "/product", //首页
        name: "ProductList",
        component: ProductList,
    },
    {
        path: "/search", //首页
        name: "SearchPage",
        component: SearchPage,
    },

];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;