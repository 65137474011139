<template>
  <div class="productdetail">
    <img
      src="@/assets/img/bg.png"
      class="pc"
      style="width: 100%; margin-top: 80px"
    />
    <img
      src="@/assets/img/mobile-bg.png"
      class="mobile"
      style="width: 100%; margin-top: 60px"
    />

    <div class="productdetail_main_container">
      <!-- 产品详情 -->

      <div class="pro_all_info_box">
        <div class="pro_pic_box">
          <van-image
            class="pro_main_pic"
            style="border-radius: 8px"
            fit="cover"
            :src="dataMsg.image"
          >
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </div>
        <div class="pro_info_box">
          <!-- 产品详情 -->
          <div class="info_main_box">
            <div class="pro_title_box">
              <div class="title_txt font40">{{ dataMsg.title }}</div>
              <div class="pro_03_box">
                <!-- <img class="pro_03" src="../assets/images/pro_03.png" alt="" /> -->
              </div>
            </div>
            <div class="pro_price font30">
              ≈$ {{ Number(dataMsg.price / $env.rate).toFixed(0) }}.00
            </div>
            <div class="lines"></div>

            <div class="to_buy_box">
              <div class="buy_txt font30 wahtsaapp" @click="openSite(dataMsg.url)">
                CLICK OOPBUY TO BUY ➤➤
              </div>
              <img class="pro_02" src="../assets/img/xing.png" alt="" />
              <div class="text11234">
                Connect you with high-quality counterfeit products through
                reliable subscriptions to create a seamless shopping experience.
                You can join our discord community (<span
                  @click="openDiscord"
                  style="
                    font-family: 'JosefinSans-Italic';
                    color: #ff186b;
                    text-decoration: underline;
                  "
                  >{{ $env.discord_url }}</span
                >). We will hold activities from time to time to replenish our
                members.
              </div>
            </div>
          </div>
          <!-- 联系方式 -->
          <div class="contract_us">
            <!-- <img class="pro_04" src="../assets/img/discord-4.png" alt="" /> -->
            <!-- <img class="pro_05" src="../assets/images/pro_05.png" alt="" /> -->
          </div>
        </div>
      </div>
      <div
        class="recom_title font40"
        v-if="productList && productList.length > 0"
      >
        <div class="title_txt">QC</div>
        <div class="line"></div>
      </div>
      <!-- QC图片 -->
      <div class="qcPic_list">
        <div
          class="qc_item"
          v-for="(item, index) in productList"
          :key="index"
          @click="preview(item)"
        >
          <!-- <img class="pro_01" src="../assets/images/pro_01.jpg" alt=""> -->
          <van-image
            class="pro_01"
            style="border-radius: 8px"
            fit="cover"
            :src="item"
          >
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </div>
      </div>

      <!-- 推荐产品 -->
      <!-- <div class="recom_title font40">
                <div class="title_txt">{{ $t('为你推荐') }}</div>
                <div class="line"></div>
            </div>
            <div class="productlist_allList_box">
                <div class="product_item" v-for="(item, index) in productList" :key="index">
                    <van-image class="pro_01" style="border-radius: 8px;" fit="cover"
                        :src="require('../assets/images/pro_01.jpg')">
                        <template v-slot:loading>
                            <van-loading type="spinner" size="20" />
                        </template>
                    </van-image>
                    <div class="pro_title font18">Nike SB Dunk Low Green Apple DM0807-300</div>
                    <div class="pro_price font30">$114</div>
                </div>

            </div> -->
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant"; // 确保已经导入
export default {
  name: "",
  data() {
    return {
      productList: [],
      dataMsg: {},
    };
  },
  methods: {
    openSite(item) {
      if (item) {
        window.open(item+'?inviteCode='+this.$env.invite_code);
      }
    },
    preview(url) {
      ImagePreview({
        images: [url],
        closeable: true,
      });
    },
    openDiscord() {
      window.open(this.$env.discord_url);
    },
    getProudts() {
      this.$api
        .getProductDetail({ id: this.$route.query.product_id })
        .then((res) => {
          if (res.data.code == 200) {
            this.dataMsg = res.data.data;
            this.productList = res.data.data.description_image;
            //   this.productList = res.data.data.products.data;
          }
        });
    },
  },
  mounted() {
    //dom 准备完毕
    window.scrollTo(0, 0);
  },
  created() {
    //应用创建
    this.getProudts();
  },
};
</script>

<style>
.wahtsaapp {
  /* animation: heartbeat 2s infinite; */
  background: linear-gradient(90deg, #000, #ff186b, #43e97b, #38f9d7);
  background-size: 400%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: wave-animation 3s ease infinite;
}
/* 定义波浪动画 */
@keyframes wave-animation {
  0% {
    background-position: 0%;
  }
  50% {
    background-position: 100%;
  }
  100% {
    background-position: 0%;
  }
}
.van-image__img {
  border-radius: 8px !important;
}
</style>
<style lang="less" scoped>
.productdetail {
  width: 100%;
  min-height: 100vh;
  // padding-top: 150px;

  .productdetail_main_container {
    width: 1400px;
    margin: 0 auto;
    margin-top: 30px;

    .pro_all_info_box {
      display: flex;
      flex-direction: row;

      .pro_pic_box {
        flex: 1;
        display: flex;
        justify-content: left;

        .pro_main_pic {
          width: 85%;
          aspect-ratio: 1 / 1;
          /* 宽高比设置为 1:1 */
          object-fit: cover;
          border-radius: 8px;
        }
      }

      .pro_info_box {
        flex: 1;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .pro_title_box {
          display: flex;
          align-items: center;
          // justify-content: center;

          .title_txt {
            color: #000;
            font-weight: 600;
          }

          .pro_03 {
            width: 50px;
            height: 50px;
            margin-left: 8px;
            cursor: pointer;
          }
        }

        .pro_price {
          color: #ff186b;
          font-weight: 600;
          margin-top: 16px;
        }

        .lines {
          width: 100%;
          border: 1px solid #999;
          opacity: 0.1;
          margin: 16px 0;
        }

        .to_buy_box {
          cursor: pointer;

          .buy_txt {
            // color: #000;
            // font-weight: 600;
            margin-bottom: 16px;
          }
          img {
            height: 40px;
          }
        }

        .contract_us {
          .pro_04 {
            width: 60px;
            // height: 60px;
            cursor: pointer;
          }

          .pro_05 {
            width: 60px;
            height: 60px;
            margin-left: 20px;
            cursor: pointer;
          }
        }
      }
    }

    .qcPic_list {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      margin-top: 16px;

      .qc_item {
        width: calc((100% - 75px) / 6);
        margin-top: 25px;
        cursor: pointer;

        .pro_01 {
          width: 100%;
          aspect-ratio: 1 / 1;
          /* 宽高比设置为 1:1 */
          object-fit: cover;
          border-radius: 8px;
        }

        .pro_title {
          color: #333;
          font-weight: 600;
          margin-top: 16px;
        }

        .pro_price {
          margin-top: 16px;
          color: #ff186b;
          font-weight: 700;
        }
      }
    }

    .recom_title {
      .title_txt {
        color: #000;
        font-weight: 600;
        margin-top: 30px;
      }

      .line {
        background: #000;
        width: 60px;
        height: 5px;
        border-radius: 10px;
      }
    }

    .productlist_allList_box {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      margin-top: 16px;

      .product_item {
        width: calc((100% - 75px) / 6);
        margin-top: 25px;
        cursor: pointer;

        .pro_01 {
          width: 100%;
          aspect-ratio: 1 / 1;
          /* 宽高比设置为 1:1 */
          object-fit: cover;
          border-radius: 8px;
        }

        .pro_title {
          color: #333;
          font-weight: 600;
          margin-top: 16px;
        }

        .pro_price {
          margin-top: 16px;
          color: #ff186b;
          font-weight: 700;
        }
      }
    }

    .video_list_box {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      margin-top: 16px;

      .video_item {
        width: calc((100% - 75px) / 2);
        margin-top: 25px;
        cursor: pointer;

        .pro_01 {
          width: 100%;
          aspect-ratio: 1 / 1;
          /* 宽高比设置为 1:1 */
          object-fit: cover;
          border-radius: 8px;
        }

        .pro_title {
          color: #333;
          font-weight: 600;
          margin-top: 16px;
        }

        .pro_price {
          margin-top: 16px;
          color: #ff186b;
          font-weight: 700;
        }
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .productdetail {
    width: 100%;
    min-height: 100vh;
    // padding-top: 150px;

    .productdetail_main_container {
      width: 100% !important;
      padding: 0 16px !important;
      margin-top: 30px;

      .pro_all_info_box {
        display: flex;
        flex-direction: column !important;
        align-items: center;

        .pro_pic_box {
          width: 100% !important;
          display: flex;
          justify-content: center !important;

          .pro_main_pic {
            width: 100% !important;
            aspect-ratio: 1 / 1;
            /* 宽高比设置为 1:1 */
            object-fit: cover;
            border-radius: 8px;
          }
        }

        .pro_info_box {
          padding: 0 16px !important;

          justify-content: left !important;

          .pro_title_box {
            align-items: center !important;

            .title_txt {
              font-size: 15px !important;
            }

            .pro_03 {
              width: 20px !important;
              height: 20px !important;
              margin-left: 12px !important;
            }
          }

          .to_buy_box {
            cursor: pointer;

            .buy_txt {
              //   color: #000;
              //   font-weight: 600;
              margin-bottom: 16px;
              font-size: 22px;
            }
            img {
              height: 30px !important;
            }
          }

          .contract_us {
            margin-top: 20px !important;
            .pro_04 {
              width: 30px !important;
              height: 30px !important;
              cursor: pointer;
            }

            .pro_05 {
              width: 30px !important;
              height: 30px !important;
              margin-left: 16px;
              cursor: pointer;
            }
          }
        }
      }

      .qcPic_list {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-top: 25px !important;

        .qc_item {
          width: calc((100% - 30px) / 3);
          margin-top: 0 !important;
          cursor: pointer;

          .pro_01 {
            width: 100%;
            aspect-ratio: 1 / 1;
            /* 宽高比设置为 1:1 */
            object-fit: cover;
            border-radius: 8px;
          }
        }
      }

      .recom_title {
        .title_txt {
          color: #000;
          font-weight: 600;
          margin-top: 20px !important;
          font-size: 18px !important;
        }

        .line {
          background: #000;
          width: 20px !important;
          height: 3px !important;
          border-radius: 10px;
        }
      }

      .productlist_allList_box {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-top: 0 !important;

        .product_item {
          width: calc((100% - 15px) / 2);
          margin-top: 25px;
          cursor: pointer;

          .pro_01 {
            width: 100%;
            aspect-ratio: 1 / 1;
            /* 宽高比设置为 1:1 */
            object-fit: cover;
            border-radius: 8px;
          }

          .pro_title {
            color: #333;
            font-weight: 600;
            margin-top: 16px;
          }

          .pro_price {
            margin-top: 16px;
            color: #ff186b;
            font-weight: 700;
          }
        }
      }

      .video_list_box {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-top: 16px;

        .video_item {
          width: calc((100% - 75px) / 2);
          margin-top: 25px;
          cursor: pointer;

          .pro_01 {
            width: 100%;
            aspect-ratio: 1 / 1;
            /* 宽高比设置为 1:1 */
            object-fit: cover;
            border-radius: 8px;
          }

          .pro_title {
            color: #333;
            font-weight: 600;
            margin-top: 16px;
          }

          .pro_price {
            margin-top: 16px;
            color: #ff186b;
            font-weight: 700;
          }
        }
      }
    }
  }
}
</style>