<template>
  <div id="app" class="app">
    <AppTop></AppTop>
    <router-view />
    <Footer></Footer>
    <img
      class="discord_msg discord_msg_anti pc"
      src="@/assets/img/discord-4.png"
      width="50px"
      @click="openDiscord"
    />
    <img
      class="discord_msg discord_msg_anti mobile"
      src="@/assets/img/discord-4.png"
      width="40px"
      @click="openDiscord"
    />
  </div>
</template>

<script>
import AppTop from "../src/views/AppTop.vue";
import Footer from "../src/views/Footer.vue";
export default {
  name: "App",
  components: { AppTop, Footer },

  data() {
    return {};
  },
  methods: {
    openDiscord(){
      window.open(this.$env.discord_url)
    },
  },
  mounted() {},
  created() {},
};
</script>
<style>
@import url("./assets/css/animate.css");
</style>
<style lang="less" scoped>
.discord_msg {
  position: fixed;
  bottom: 120px;
  right: 70px;
}
@media screen and (max-width: 960px) {
  .discord_msg {
    position: fixed;
    bottom: 60px;
    right: 30px;
  }
}
.discord_msg_anti {
  animation: heartbeat 2s infinite;
}
@keyframes heartbeat {
  0%,
  100% {
    transform: scale(1); /* 起始和结束时缩放为原始大小 */
  }
  50% {
    transform: scale(1.2); /* 中间时放大 */
  }
}
</style>
