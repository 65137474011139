<template>
    <div class="footer_box">
        <div class="footer_pc">
            <div class="main_container">
                <div class="footer_main_box">
                    <div class="footer_info_list">
                        <div class="footer_info_title font18 ">Shopping Agent Guide</div>
                        <div class="nav_list">
                            <div class="nav_item font16" v-for="(item, index) in ShoppingList" :key="index"
                                @click="openUrl(item.url)">{{ item.title }}</div>

                        </div>
                    </div>

                    <div class="footer_info_list">
                        <div class="footer_info_title font18 ">Payment</div>
                        <div class="nav_list">
                            <div class="nav_item font16" v-for="(item, index) in PaymentList" :key="index"
                                @click="openUrl(item.url)">{{ item.title }}</div>

                        </div>
                    </div>
                    <div class="footer_info_list">
                        <div class="footer_info_title font18 ">Delivery</div>
                        <div class="nav_list">
                            <div class="nav_item font16" v-for="(item, index) in DeliveryList" :key="index"
                                @click="openUrl(item.url)">{{ item.title }}</div>

                        </div>
                    </div>
                    <div class="footer_info_list">
                        <div class="footer_info_title font18 ">Warehouse & After-sale</div>
                        <div class="nav_list">
                            <div class="nav_item font16" v-for="(item, index) in WarehouseList" :key="index"
                                @click="openUrl(item.url)">{{ item.title }}</div>

                        </div>
                    </div>


                </div>

                <!-- 支付方式以及其他信息 -->
                <div class="otherinfo_container">
                    <div class="other_info">
                        <img class="pro_07" src="../assets/images/pro_07.jpeg" alt="">
                        <div class="info_list">
                            <div class="info_item font16" v-for="(item, index) in otherList" :key="index"
                                @click="openUrl(item.url)">{{ item.title }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer_mob">
            <div class="footer_mob_main">
                <van-collapse v-model="activeName" accordion>
                    <van-collapse-item title="Shopping Agent Guide" name="1">
                        <div class="nav_list">
                            <div class="nav_item font16" v-for="(item, index) in ShoppingList" :key="index"
                                @click="openUrl(item.url)">{{ item.title }}</div>

                        </div>
                    </van-collapse-item>
                    <van-collapse-item title="Payment" name="2">
                        <div class="nav_list">
                            <div class="nav_item font16" v-for="(item, index) in PaymentList" :key="index"
                                @click="openUrl(item.url)">{{ item.title }}</div>

                        </div>
                    </van-collapse-item>
                    <van-collapse-item title="Delivery" name="3">
                        <div class="nav_list">
                            <div class="nav_item font16" v-for="(item, index) in DeliveryList" :key="index"
                                @click="openUrl(item.url)">{{ item.title }}</div>

                        </div>
                    </van-collapse-item>

                    <van-collapse-item title="Warehouse & After-sale" name="4">
                        <div class="nav_list">
                            <div class="nav_item font16" v-for="(item, index) in WarehouseList" :key="index"
                                @click="openUrl(item.url)">{{ item.title }}</div>

                        </div>
                    </van-collapse-item>
                </van-collapse>
                <div class="otherinfo_container">
                    <div class="other_info">
                        <img class="pro_07" src="../assets/images/pro_07.jpeg" alt="">
                        <div class="info_list">
                            <div class="info_item font16" v-for="(item, index) in otherList" :key="index"
                                @click="openUrl(item.url)">{{ item.title }}</div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "",
    data() {
        return {
            ShoppingList: [{ title: 'Service & Fees', url: 'https://oopbuy.com/notice/1740653781924810754' },
            { title: 'Tutorial', url: 'https://oopbuy.com/notice/1740653409764216834' },
            { title: 'Notes Before Purchasing', url: 'https://oopbuy.com/notice/1772880724846247937' },
            { title: 'Returns and Refunds', url: 'https://oopbuy.com/notice/1740656181012172801' },
            { title: 'Notes Before Purchasing', url: 'https://oopbuy.com/notice/1772882548114391041' },
            ],
            PaymentList: [{ title: 'Top up', url: 'https://oopbuy.com/notice/1740660085586235394' },
            { title: 'International Credit Card', url: 'https://oopbuy.com/notice/1740661217771491330' },
            { title: 'Paypal', url: 'https://oopbuy.com/notice/1740660571185975297' }

            ],
            DeliveryList: [{ title: 'Receipt Information', url: 'https://oopbuy.com/notice/1740663892185550850' },
            { title: 'Charges', url: 'https://oopbuy.com/notice/1740661564720123906' },
            { title: 'Packaging', url: 'https://oopbuy.com/notice/1740661986205732866' },
            { title: 'Customs and Taxation', url: 'https://oopbuy.com/notice/1740663384020455425' },
            { title: 'Delivery Notice', url: 'https://oopbuy.com/notice/1772881180494462977' }

            ],
            WarehouseList: [{ title: 'What if fragile products arrived damaged?', url: 'https://oopbuy.com/notice/1772884280521326593' },
            { title: 'What do I do if there is a product issue after I received it?', url: 'https://oopbuy.com/notice/1772884100300472322' },
            { title: 'How to track my parcel?', url: 'https://oopbuy.com/notice/1772883184688103426' },
            { title: 'What is "out for delivery"?', url: 'https://oopbuy.com/notice/1772882981318885377' },
            { title: 'Storage', url: 'https://oopbuy.com/notice/1740664252140720130' },
            { title: 'How to apply for aftersales?', url: 'https://oopbuy.com/notice/1772884667429093377' },
            { title: 'Insurance and Compensation', url: 'https://oopbuy.com/notice/1740665065227522050' },
            { title: 'Notices of the parcel receiving', url: 'https://oopbuy.com/notice/1772883584883425281' },
            { title: 'Inspection Information', url: 'https://oopbuy.com/notice/1740664942636404738' },

            ],
            otherList: [{ title: 'Service Terms And Users Management', url: 'https://oopbuy.com/notice/1743203214533169153' },
            { title: 'Contact Us', url: 'https://oopbuy.com/notice/1743203920065433601' },
            { title: 'Privacy Statement', url: 'https://oopbuy.com/notice/1743203728339603458' },
            { title: 'About OOPBUY', url: 'https://oopbuy.com/notice/1743202915642871810' }
            ],
            activeName: '1',



        };
    },
    methods: {

        openUrl(url) {
            window.open(url);
        }

    },
    mounted() {
        //dom 准备完毕
    },
    created() {
        //应用创建
    },
};
</script>


<style lang="less">
.footer_mob {
    display: none !important;
}

.footer_box {
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    display: block;

    .footer_main_box {
        width: 1400px;
        margin: 50px auto;
        display: flex;
        justify-content: space-between;
        gap: 70px;

        .footer_info_list {

            .footer_info_title {
                color: black;
                font-weight: 700;

            }

            .nav_list {
                .nav_item {
                    font-weight: 500;
                    color: #333;
                    margin-top: 12px;
                    cursor: pointer;
                    font-family: JosefinSans-Italic;

                }

                .nav_item:hover {
                    color: #FF186B;
                }
            }
        }
    }

    .otherinfo_container {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        margin-top: 20px;

        .other_info {


            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 1400px;
            margin: 0 auto;
            padding: 40px 0;

            .pro_07 {
                height: 30px;
            }

            .info_list {
                display: flex;
                gap: 15px;

                .info_item {
                    font-weight: 600;
                    color: black;
                    font-family: JosefinSans-Italic;
                    text-decoration: underline;
                }

                .info_item:hover {
                    color: #FF186B;
                    cursor: pointer;
                }
            }
        }
    }

}

@media screen and (max-width:960px) {
    .footer_pc {
        display: none !important;
    }

    .footer_mob {
        display: block !important;
    }

    .nav_list {
        .nav_item {
            font-weight: 500;
            color: #333;
            margin-top: 12px;
            cursor: pointer;
            font-family: JosefinSans-Italic;

        }

        .nav_item:hover {
            color: #FF186B;
        }
    }

    .footer_box {
        width: 100%;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        margin-top: 20px;

        .footer_main_box {
            width: 100% !important;
            margin: 50px auto;
            display: block !important;
            justify-content: space-between;
            gap: 0 !important;

            .footer_info_list {
                .footer_info_title {
                    color: black;
                    font-weight: 700;

                }

                .nav_list {
                    .nav_item {
                        font-weight: 500;
                        color: #333;
                        margin-top: 12px;
                        cursor: pointer;
                        font-family: JosefinSans-Italic;

                    }

                    .nav_item:hover {
                        color: #FF186B;
                    }
                }
            }
        }

        .otherinfo_container {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            margin-top: 20px;

            .other_info {

                display: block !important;
                align-items: center;
                justify-content: space-between;
                width: 100% !important;
                margin: 0 auto;
                padding: 40px 0;
                padding: 15px 15px ;

                .pro_07 {
                    height: auto !important;
                    width: 100% !important;
                }

                .info_list {
                    display: flex;
                    gap: 15px;
                    flex-wrap: wrap !important;
                    margin-top: 15px;

                    .info_item {
                        font-weight: 600;
                        color: black;
                        font-family: JosefinSans-Italic;
                        text-decoration: underline;
                    }

                    .info_item:hover {
                        color: #FF186B;
                        cursor: pointer;
                    }
                }
            }
        }

    }
}
</style>