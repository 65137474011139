import { requests } from './request';
import env from "../../env"

export default {
   
    getCategories(data) {
        return requests.post('api/categories', data);
    },

    getProducts(data) {
        return requests.post('api/products', data);
    },

    getProductDetail(data){
        return requests.post('api/productDetail', data);
    }
};