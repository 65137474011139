<template>
  <div class="Home">
    <!-- <img src="@/assets/img/bg.webp" style="width: 100%;"/> -->

    <div class="home-header">
      <!-- <div style="width: 60%;"></div> -->

      <div class="home-left">
        <div class="home-title bounceInLeft wow an-two">
          Linking The Best Replica with Trusted Agents
        </div>
        <div class="home-content bounceInRight wow an-two">
          Connecting You to Premium Replica Products with Reliable Agents for a
          Seamless Shopping Experience
        </div>
        <div class="home-btn bounceInRightSc wow an-two" @click="openURL">
          <span> ➤➤ Click to access our all-in-one links spreadsheet ➤➤ </span>
        </div>
      </div>
    </div>
    <div class="home-hot-content">
      <div class="hot-title bounceInLeft wow an-two">Hot Shoping</div>
      <div class="hot-content bounceInRightSc wow an-two">
        Recommend The Most Selling And Feedback Best
      </div>
      <div class="hot-product">
        <div
          class="product"
          v-for="item in productList"
          :key="item.div"
          @click="
            $router.push({
              path: '/detail',
              query: {
                product_id: item.id,
              },
            })
          "
        >
          <img :src="item.image" />
          <div class="pro_title">{{ item.title }}</div>
          <div class="amount">
            ≈$ {{ Number(item.price / $env.rate).toFixed(0) }}.00
          </div>
        </div>
      </div>
    </div>

    <!-- 内容 -->
    <div class="home-classification">
      <div class="home-class-content">
        <div class="class-left" @click="$router.push({
          path:'/product',
          query:{
            cate_id:28
          }
        })">
          <div class="class-left-a bounceInLeft wow an-two">
            OG Retro Sneakers
          </div>
          <div class="class-left-c bounceInRightSc wow an-two">
            Optimum batch and QC service
          </div>
          <div class="class-left-b bounceInRight wow an-two">View All</div>
        </div>
        <div class="class-right">
          <div class="img1" @click="$router.push({
          path:'/product',
          query:{
            cate_id:84
          }
        })">
            <div class="class-left-a bounceInLeft wow an-two">
              OG Retro Jeans
            </div>
            <div class="class-left-c bounceInRightSc wow an-two">
              Optimum batch and QC service
            </div>
            <div class="class-left-b bounceInRight wow an-two">View All</div>
          </div>
          <div class="img2" @click="$router.push({
          path:'/product',
          query:{
            cate_id:82
          }
        })">
            <div class="class-left-a bounceInLeft wow an-two">
              OG Retro Clothes
            </div>
            <div class="class-left-c bounceInRightSc wow an-two">
              Optimum batch and QC service
            </div>
            <div class="class-left-b bounceInRight wow an-two">View All</div>
          </div>
        </div>
      </div>
    </div>
    <!-- youtube -->
    <!-- <div class="home-hot-content">
      <div class="hot-title bounceInLeft wow an-two">RepShoes & YouTube</div>
      <div class="hot-content bounceInRightSc wow an-two">
        This is all-in-one home-title Shopping
      </div>
      <div class="hot-product  youtube">
        <div class="youtube_list" v-for="item in youtubeList" :key="item.div">
          <iframe class="youtube" src="https://www.youtube.com/embed/O-6ZQtgG3Qs" title="Top 10 BEST Sneakers for Summer!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>
    </div> -->

    <!-- 如何运输 -->
    <div class="home-hot-content">
      <div class="hot-title bounceInLeft wow an-two">
        One-stop shopping service
      </div>
      <!-- <div class="hot-content bounceInRightSc wow an-two">
        Recommend The Most Selling And Feedback Best
      </div> -->
    </div>
    <div class="how_box">
      <div class="how_list">
        <div class="how_item bounceInLeft wow an-two">
          <div class="item_main">
            <img class="pro_011" src="../assets/images/pro_011.png" alt="" />
            <div class="item_title">Place an order</div>
            <div class="item_content">
              It is easy to buy Chinese products both online and offline. Simply
              paste the link to the product you want to place an order. Enjoy
              our silky service that makes it easy to go from browsing to
              buying.
            </div>
          </div>
          <img class="pro_015" src="../assets/images/pro_015.webp" alt="" />
        </div>
        <div class="how_item bounceInLeft wow an-two">
          <div class="item_main">
            <img class="pro_011" src="../assets/images/pro_012.png" alt="" />
            <div class="item_title">Delivery to our warehouse</div>
            <div class="item_content">
              By shopping from different sellers through our service, we will
              efficiently integrate your purchase orders. All goods will be
              shipped to Oopbuy China warehouse to simplify the process for your
              convenience.
            </div>
          </div>
          <img class="pro_015" src="../assets/images/pro_015.webp" alt="" />
        </div>
        <div class="how_item bounceInRight wow an-two">
          <div class="item_main">
            <img class="pro_011" src="../assets/images/pro_013.png" alt="" />
            <div class="item_title">Quality assurance</div>
            <div class="item_content">
              Once your products arrive at the Oopbuy warehouse, they undergo a
              comprehensive quality check. Our team carefully checks each item
              for defects, correct size, color, etc. With Oopbuy, you can have
              peace of mind and be satisfied with the enthusiastic after-sales
              service.
            </div>
          </div>
          <img class="pro_015" src="../assets/images/pro_015.webp" alt="" />
        </div>
        <div class="how_item bounceInRight wow an-two">
          <div class="item_main">
            <img class="pro_011" src="../assets/images/pro_014.png" alt="" />
            <div class="item_title">Global shipping becomes easy</div>
            <div class="item_content">
              Select products in the warehouse and then easily integrate them
              into one package. With Oopbuy's reliable global shipping service,
              you can have your purchases delivered directly to your door.
              Within reach, enjoy the convenience of world-class transportation.
            </div>
          </div>
          <div class="pro_015"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";

export default {
  name: "",
  data() {
    return {
      productList: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      youtubeList: [{}, {}, {}, {}, {}, {}, {}],
      productList: this.$env.hot_proudts,
    };
  },
  methods: {
    openURL() {
      window.open(this.$env.home_url);
    },
  },
  created() {
    localStorage.removeItem("shop_id");
  },
  mounted() {
    //dom 准备完毕
    window.scrollTo(0, 0);
    var wow = new WOW({
      boxClass: "wow", // animated element css class (default is wow)
      animateClass: "animated", // animation css class (default is animated)
      offset: 0, // distance to the element when triggering the animation (default is 0)
      mobile: true, // trigger animations on mobile devices (default is true)
      live: true, // act on asynchronously loaded content (default is true)
      callback: function (box) {
        // the callback is fired every time an animation is started
      },
      scrollContainer: null, // optional scroll container selector, otherwise use window
    });
    wow.init();
  },
  created() {
    //应用创建
    
  },
};
</script>


<style lang="less" scoped>
</style>