<template>
  <div class="productlist">
    <img
      src="@/assets/img/bg.png"
      class="pc"
      style="width: 100%; margin-top: 80px"
    />
    <img
      src="@/assets/img/mobile-bg.png"
      class="mobile"
      style="width: 100%; margin-top: 60px"
    />
    <div class="catory_list">
      <div
        class="catory_title"
        :class="selectData.id == item.id && 'text-red'"
        v-for="(item, index) in cateList"
        :key="index"
        @click="changeItem(item)"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="productlist_main_container">
      <div class="product_list_h1 font60">
        <!-- <span class="bounceInLeft wow an-two">{{ selectData.title }}</span
        ><span class="product_num font16 bounceInRightSc wow an-two"></span> -->
      </div>
      <div class="product_classify_desc font16 bounceInRightSc wow an-two">
        {{ selectData.desc }}
      </div>

      <div class="productlist_new">
        <el-empty
          description="No Data ~"
          v-if="!productList || productList.length < 1"
        ></el-empty>

        <div class="hot-product">
          <div
            class="product"
            v-for="item in productList"
            :key="item.div"
            @click="pushPage(item)"
          >
            <img :src="item.image" />
            <div>{{ item.title }}</div>
            <div class="amount">
              ≈$ {{ Number(item.price / $env.rate).toFixed(0) }}.00
            </div>
          </div>
        </div>

        <div class="bttom-fenye pc" v-if="total > 0">
          <el-pagination
            @current-change="pageChange"
            :current-page="page"
            background
            :page-size="20"
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>

        <div class="bttom-fenye mobile" v-if="total > 0">
          <el-pagination
            @current-change="pageChange"
            :pager-count="5"
            :current-page="page"
            background
            :page-size="20"
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";

export default {
  name: "",
  data() {
    return {
      productList: [],
      cateList: [],
      selectData: {},
      total: 0,
      page: 1,
      proId: 0,
    };
  },
  methods: {
    changeItem(item) {
      this.page = 1;
      this.selectData = item;
      this.proId = item.id;
      this.getProudts(item.id);
      this.$router.push({ path: "/product", query: { cate_id: item.id } });
    },
    findItemById(id, data) {
      return data.find((item) => item.id == id);
    },
    getProudts(id) {
      this.$api
        .getProducts({ category_id: id, per_page: 20, page: this.page })
        .then((res) => {
          if (res.data.code == 200) {
            this.productList = res.data.data.products.data;
            this.total = res.data.data.products.total;
          }
        });
    },
    pushPage(item) {
      this.$router.push({
        path: "/detail",
        query: {
          product_id: item.id,
        },
      });
    },
    pageChange(num) {
      this.page = num;
      this.getProudts(this.proId);
    },
    checkData(id) {
      this.proId = id;
      this.page = 1;
      const result = this.$categories.find((item) =>
        item.children.some((child) => child.id == id)
      );

      const itemWithId28 = result
        ? result.children.find((child) => child.id == id)
        : null;

      this.cateList = result.children;
      this.selectData = itemWithId28;

      this.getProudts(itemWithId28.id);
    },
  },
  mounted() {
    //dom 准备完毕
    //dom 准备完毕
    window.scrollTo(0, 0);
    var wow = new WOW({
      boxClass: "wow", // animated element css class (default is wow)
      animateClass: "animated", // animation css class (default is animated)
      offset: 0, // distance to the element when triggering the animation (default is 0)
      mobile: true, // trigger animations on mobile devices (default is true)
      live: true, // act on asynchronously loaded content (default is true)
      callback: function (box) {
        // the callback is fired every time an animation is started
      },
      scrollContainer: null, // optional scroll container selector, otherwise use window
    });
    wow.init();
  },
  beforeDestroy() {
    // localStorage.removeItem("shop_id")
  },

  created() {
    //应用创建

    this.checkData(this.$route.query.cate_id);
    
    this.$bus.$on("change", (res) => {
      // this.getProudts(res);
      this.checkData(res);
    });
  },
};
</script>

<style>
.van-image__img {
  border-radius: 8px !important;
}
</style>
<style lang="less" scoped>
.catory_list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0px 15px;
  gap: 15px;
  margin-top: 30px;
  font-family: JosefinSans-Bold;
  .catory_title {
    font-size: 18px;
    text-decoration: underline;
    cursor: pointer;
  }
}
.productlist {
  width: 100%;
  min-height: 100vh;

  .productlist_main_container {
    width: 1400px;
    margin: 0 auto;
    padding-top: 30px;

    .product_list_h1 {
      text-align: center;
      color: #000;
      font-weight: 600;

      .product_num {
        color: #ff186b;
        font-weight: 600;
      }
    }

    .product_classify_desc {
      color: #333333;
      text-align: center;
      margin-top: 16px;
      line-height: 28px;
      font-family: JosefinSans-Italic;
    }

    .productlist_allList_box {
      display: flex;
      flex-wrap: wrap;
      gap: 25px;
      margin-top: 16px;

      .product_item {
        width: calc((100% - 75px) / 4);
        margin-top: 25px;
        cursor: pointer;

        .pro_01 {
          width: 100%;
          aspect-ratio: 1 / 1;
          /* 宽高比设置为 1:1 */
          object-fit: cover;
          border-radius: 8px;
        }

        .pro_title {
          color: #333;
          font-weight: 600;
          margin-top: 16px;
        }

        .pro_price {
          margin-top: 16px;
          color: #ff186b;
          font-weight: 700;
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .productlist {
    .productlist_main_container {
      width: 100% !important;

      padding: 0 16px !important;
      padding-top: 20px !important;
    }
  }
}
</style>